// import Aos from "aos";
import frontendImg from '../../assets/images/front-end.png'
import backendImg from '../../assets/images/backend.png'
import uiImg from '../../assets/images/design.png'
import appsImg from '../../assets/images/apps.png'



const Services = () => {
    return ( 
        <section id="services">
            <div className="container lg:pt-5">
                <div className="text-center">
                    <h2 className="text-headingColor font-[800] text-[2.4rem] mb-5">
                        services 
                    </h2>
                    <p className="lg:max-w-[600px] lg:mx-auto text-headingColor font-[500] text-[16px] leading-7">
                         I deliver quality products with effective communication and strong time management skills. Explore how my expertise can benefit your projects
                    </p>
                </div>

                <div className="flex flex-col justify-center sm:py-12">
                    <div className="w-full py-3 sm:max-w-xl sm:mx-auto sm:px-0">
                         <div className="relative text-gray-700 antialiased text-sm font-semibold">
                            {/* ======= vertical line through the middle ======== */}
                            <div className="hidden absolute w-1 sm:block bg-teal-200 h-full left-1/2 transform
                             -translate-x-1/2"></div>

                             {/* ====== left card ======= */}
                             <div className="mt-6 sm:mt-0 sm:mb-12">
                                <div className="flex items-center flex-col sm:flex-row">

                                    <div className="flex justify-start w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pr-8">
                                            <div data-aos='fade-right' data-aos-duration='1200' className="bg-white p-4 rounded shadow group hover:bg-primaryColor  cursor-pointer ease-in duration-150">
                                                <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-2xl">Frontend Development</h3>

                                                <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:  ">
                                                Specializing in Frontend Development, I craft compelling user interfaces for both websites and applications. My focus is on delivering polished, visually captivating experiences that elevate your online presence. By leveraging the latest technologies and design principles, I ensure that your digital platforms meet industry standards and exceed user expectations. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure className="">
                                            <img src={frontendImg} alt="" />
                                        </figure>
                                    </div>
                                </div>
                             </div>

                             {/* ====== right card ===== */}

                             <div className="mt-6 sm:mt-0 sm:mb-12">
                                <div className="flex items-center flex-col sm:flex-row">

                                    <div className="flex justify-end w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pl-8">
                                            <div data-aos='fade-left' data-aos-duration='1200' className="bg-white p-4 rounded shadow group hover:bg-primaryColor  cursor-pointer ease-in duration-150">
                                                <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-2xl">Backend Development</h3>

                                                <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] ">
                                                    With a focus on optimizing performance and security, I design robust solutions that lay the foundation for scalable and reliable digital experiences. From database management to server-side logic, I prioritize the creation of a strong backend infrastructure, empowering your digital presence with the stability and functionality it deserves.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure className="">
                                            <img src={backendImg} alt="" />
                                        </figure>
                                    </div>
                                </div>
                             </div>

                             
                             {/* ====== left card ======= */}
                             <div className="mt-6 sm:mt-0 sm:mb-12">
                                <div className="flex items-center flex-col sm:flex-row">

                                    <div className="flex justify-start w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pr-8">
                                            <div data-aos='fade-right' data-aos-delay='50'  data-aos-duration='1300' className="bg-white p-4 rounded shadow group hover:bg-primaryColor  cursor-pointer ease-in duration-150">
                                                <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-2xl">UX/UI</h3> 

                                                <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] ">
                                                I create intuitive and visually appealing user experiences that captivate and engage your audience. Through thoughtful design and seamless interactions, I enhance the usability and aesthetics of your website or application.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure className="">
                                            <img src={uiImg} alt="" />
                                        </figure>
                                    </div>
                                </div>
                             </div>

                             {/* ====== right card ===== */}

                             <div className="mt-6 sm:mt-0 sm:mb-12">
                                <div className="flex items-center flex-col sm:flex-row">

                                    <div className="flex justify-end w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pl-8">
                                        <div 
                                            data-aos='fade-left'
                                            data-aos-delay='100'
                                            data-aos-duration='1400'
                                            className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150">
                                            <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-2xl">Cloud Development</h3>

                                            <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500]">
                                            I specialize in creating robust and user-friendly solutions in cloud development, ensuring seamless experiences across devices. From designing scalable architectures to optimizing cloud infrastructure and implementing cloud-native solutions, I ensure that your applications are well-prepared to meet the demands of today's digital landscape.
                                            </p>
                                        </div>

                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure className="">
                                            <img src={appsImg} alt="" /> 
                                        </figure>
                                    </div>
                                </div>
                             </div>

                             {/* ====== left card ======= */}
                             <div className="mt-6 sm:mt-0 sm:mb-12">
                                <div className="flex items-center flex-col sm:flex-row">

                                    <div className="flex justify-start w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pr-8">
                                            <div data-aos='fade-right' data-aos-delay='50'  data-aos-duration='1300' className="bg-white p-4 rounded shadow group hover:bg-primaryColor  cursor-pointer ease-in duration-150">
                                                <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-2xl">Databases</h3> 

                                                <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] ">
                                                I integrate various databases, such as MongoDB, Firebase, PostgreSQL, and more, to optimize the functionality and performance of your applications. These databases serve as robust data storage solutions, enabling efficient data management and retrieval.                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure className="">
                                            <img src={uiImg} alt="" />
                                        </figure>
                                    </div>
                                </div>
                             </div>


                             {/* ====== right card ===== */}

                             <div className="mt-6 sm:mt-0 sm:mb-12">
                                <div className="flex items-center flex-col sm:flex-row">

                                    <div className="flex justify-end w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pl-8">
                                        <div 
                                            data-aos='fade-left'
                                            data-aos-delay='100'
                                            data-aos-duration='1400'
                                            className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150">
                                            <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-2xl">Testing</h3>

                                            <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500]">
                                            Ensure the security and reliability of your sites and APIs. I implement robust testing practices to identify and address vulnerabilities, guaranteeing a secure online presence. From penetration testing to quality assurance, I prioritize industry-leading strategies to fortify your applications and deliver a seamless user experience.
                                            </p>
                                        </div>

                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure className="">
                                            <img src={appsImg} alt="" /> 
                                        </figure>
                                    </div>
                                </div>
                             </div>

                               {/* ====== left card ======= */}
                               <div className="mt-6 sm:mt-0 sm:mb-12">
                                <div className="flex items-center flex-col sm:flex-row">

                                    <div className="flex justify-start w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pr-8">
                                            <div data-aos='fade-right' data-aos-delay='50'  data-aos-duration='1300' className="bg-white p-4 rounded shadow group hover:bg-primaryColor  cursor-pointer ease-in duration-150">
                                                <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-2xl">Project Management</h3> 

                                                <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] ">
                                                Proficient in project management, I utilize methodologies such as Agile, Scrum, Lean, and more. By employing industry-leading tools and frameworks, I ensure streamlined project workflows, effective collaboration, and successful delivery. From planning to execution, my approach focuses on maximizing efficiency, fostering team synergy, and achieving project objectives with precision.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure className="">
                                            <img src={uiImg} alt="" />
                                        </figure>
                                    </div>
                                </div>
                             </div>


                             {/* ====== right card ===== */}

                             <div className="mt-6 sm:mt-0 sm:mb-12">
                                <div className="flex items-center flex-col sm:flex-row">

                                    <div className="flex justify-end w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pl-8">
                                        <div 
                                            data-aos='fade-left'
                                            data-aos-delay='100'
                                            data-aos-duration='1400'
                                            className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150">
                                            <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-2xl">SEO</h3>

                                            <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500]">
                                            Elevate your online visibility through strategic SEO practices. I specialize in optimizing websites to rank higher on search engines, increasing organic traffic and enhancing your digital presence. From keyword research to on-page optimization, I employ proven techniques to improve search engine rankings and drive meaningful results. Stay ahead in the digital landscape with SEO strategies tailored to boost your website's performance and deliver long-term success.
                                            </p>
                                        </div>

                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure className="">
                                            <img src={appsImg} alt="" /> 
                                        </figure>
                                    </div>
                                </div>
                             </div>



                        </div> 
                    </div>
                </div>
                

            </div>
        </section>
     );
}
 
export default Services;
