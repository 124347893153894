import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Helmet } from 'react-helmet'
import "aos/dist/aos.css";
import "remixicon/fonts/remixicon.css";
import logo from "./assets/images/logo.png"


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
        <Helmet>
      <title>Bruno Zamani</title>
      <meta name="description" content="Bruno Zamani - Software Developer Portfolio" />
      <link
        rel="icon"
        href= {logo}
      />
    </Helmet>
    <App />
  </React.StrictMode>
);
