import portfolioImg01 from "../images/Beautybliss.png";
import portfolioImg02 from "../images/HAHA-HUB.png";
import portfolioImg03 from "../images/read riot.png";
import portfolioImg04 from "../images/article254.png";
import portfolioImg05 from "../images/loops.jpg";
import portfolioImg06 from "../images/desktop-preview.jpg";
import portfolioImg07 from "../images/intelli.jpg";
import portfolioImg08 from "../images/zuri-health.png"
import portfolioImg09 from "../images/CNPL.png"
import portfolioImg10 from "../images/play-communications.png"
import portfolioImg11 from "../images/Play-date.png"
import portfolioImg12 from "../images/niko-na-choice.png"
import portfolioImg13 from "../images/oracle.png"

const portfolios = [
  {
    id: "01",
    imgUrl: portfolioImg08,
    category: "Web Design",
    title: "Zuri Health",
    description:
    "A virtual hospital providing quality affordable and accessible healthcare services to patients across Sub-saharan Africa",
    technologies: ["React", "Nodejs", "Express", "MongoDB"],
    siteUrl: "https://www.zuri.health/",
  },
  {
    id: "02",
    imgUrl: portfolioImg09,
    category: "app design",
    title: "Care Now Pay Later (CNPL) APP",
    description:
    "Care Now Pay Later (CNPL), a healthcare app on M-Pesa App by Safaricom is a comprehensive solution that addresses financial barriers to healthcare access. It enables users to access medical services, insurance coverage, and pharmacy medications with flexible payment options.",
    technologies: ["React Native", "Nodejs", "Express", "MongoDB"],
    siteUrl: "https://www.zuri.health/",
  },
  {
    id: "03",
    imgUrl: portfolioImg10,
    category: "web design",
    title: "Play Group",
    description:
    "At Play Group, we're more than a mobile content company; we're trailblazers shaping the future of mobile experiences in Africa and beyond. With a dynamic blend of innovation and dedication, we've pioneered diverse mobile solutions, fostered strategic partnerships, and relentlessly pursued excellence since our inception. Our journey embodies a commitment to redefining engagement, offering cutting-edge content, and creating a vibrant digital ecosystem.",
    technologies: ["Wordpress", "MongoDB", "Nodejs", "AWS"],
    siteUrl: "https://theplaygroup.net/",
  },
  {
    id: "04",
    imgUrl: portfolioImg11,
    category: "Web Design",
    title: "Dating App",
    description:
    "At Playdate, our team is more than just a group of professionals; we’re a collective of passionate individuals driven by a singular mission: to redefine love in Neo-Africa through innovative technology and heartfelt connections.",
    technologies: ["Wordpress", "Python", "SQL", "AWS"],
    siteUrl: "https://www.playdate.africa/",
  },
  {
    id: "05",
    imgUrl: portfolioImg12,
    category: "Web Design",
    title: "Niko Na Choice",
    description:
    "Bayer Foundation in Partnership with Zuri Health. Welcome to the collaborative initiative of the Bayer Foundation and Zuri Health. Our partnership is dedicated to advancing healthcare solutions and fostering innovation in medical research.",
    technologies: ["React", "MongoDB", "Nodejs", "Express"],
    siteUrl: "https://www.nikonachoice.zuri.health/",
  },
  {
    id: "06",
    imgUrl: portfolioImg13,
    category: "Web Design",
    title: "Oracle Movie App",
    description:
    "Platform for adding, editing, deleting movies from the database",
    technologies: ["React", "MongoDB", "Nodejs", "Express"],
    siteUrl: "https://the-film-oracle-client-side.vercel.app/",
  },
  // {
  //   id: "05",
  //   imgUrl: portfolioImg07,
  //   category: "Mobile Design",
  //   title: "Intellidrop: Transforming ASAL Agriculture",
  //   description: `At ALX, I spearheaded Intellidrop, a groundbreaking mobile design venture aimed at transforming agriculture in Kenya's Arid and Semi-Arid Lands (ASAL). `,
  //   technologies: ["UI/UX Design", "Figma"],
  //   siteUrl: "https://intellidrop-demo.example.com",
  //  slideDeckUrl: "https://docs.google.com/presentation/d/1Ym9ZKqf49XNl3fusW3_dVBvAXr4VWDPH/edit#slide=id.g25956dbe3e0_0_5",
  //   videoPresentationUrl: "https://www.youtube.com/watch?v=0SJyrpwlViU" 
  // },    

  // {
  //   id: "06",
  //   imgUrl: portfolioImg06,
  //   category: "Ux",
  //   title: "Credit card information",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["HTML", "CSS", "javascript"],
  //   siteUrl: "#",
  // },
  // {
  //   id: "07",
  //   imgUrl: portfolioImg07,
  //   category: "Web Design",
  //   title: "Appointment Booking Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  // },
  // {
  //   id: "08",
  //   imgUrl: portfolioImg01,
  //   category: "Web Design",
  //   title: "Finance Technology Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  // },
  // {
  //   id: "09",
  //   imgUrl: portfolioImg02,
  //   category: "Ux",
  //   title: "Video Conference Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  // },
  // {
  //   id: "10",
  //   imgUrl: portfolioImg03,
  //   category: "Ux",
  //   title: "File Sharing Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  // },
  // {
  //   id: "11",
  //   imgUrl: portfolioImg04,
  //   category: "Web Design",
  //   title: "Landing Page",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  // },
  // {
  //   id: "12",
  //   imgUrl: portfolioImg05,
  //   category: "Web Design",
  //   title: "Landing Page",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  // },
  // {
  //   id: "13",
  //   imgUrl: portfolioImg06,
  //   category: "Web Design",
  //   title: "Online Therapy Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  // },
  // {
  //   id: "14",
  //   imgUrl: portfolioImg07,
  //   category: "ux",
  //   title: "Appointment Booking Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  // },
];


// const projects = [
//   {
//     image: beautybliss,
//     title: 'Beauty Bliss',
//     description: 'An E-commerce application built with Rails API and React',
//     websiteLink: 'https://beauty-bliss-ul2a.vercel.app/',
//   },
//   {
//     image: Hahahub,
//     title: 'Meme Generator',
//     description: 'Rails and react meme generator app for moringa school project ',
//     websiteLink: 'https://haha-hub.vercel.app/',
//   },
//   {
//     image: READRIOT,
//     title: 'Library app',
//     description: 'React application that utilizes google books api',
//     websiteLink: 'https://read-riot.vercel.app/',
//   },
//   {
//     image: article254,
//     title: 'blog page',
//     description: 'article web application Built with RUBY and React ',
//     websiteLink: 'https://article254.vercel.app/',
//   },
//   {
//     image: loops,
//     title: 'Loopstudios Landing Page',
//     description: 'This is a small project that I did from Frontend Mentor',
//     websiteLink: 'https://example.com/loopstudios-demo',
//   }
// ];


export default portfolios;
