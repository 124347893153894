
import React from 'react';
import { Helmet } from "react-helmet";
import logo from "../assets/images/logo.png"

const Seo = () => {
  return (
    <Helmet>
      <title>Bruno Zamani - Full Stack Developer Portfolio</title>

      <meta name="description" content="Explore the portfolio of Bruno Zamani, a Dedicated Full-stack Developer with over 3 years of professional experience. Specializing in creating robust and scalable solutions for web applications using MERN-stack (MongoDB, ExpressJs, ReactJs, and Node.js). Skilled in crafting responsive interfaces, architecting server-side logic, ensuring optimal database design, and cybersecurity with a focus on addressing vulnerabilities using tools like Probely. Proficient in Docker for efficient application deployment and Termius for secure server management. Demonstrated expertise in integrating AI systems such as Thymia, Binar, and Helfie STI into healthcare solutions, contributing to advanced functionalities. Proven track record of collaborating with cross-functional teams to deliver seamless projects. Committed to staying abreast of emerging technologies to enhance the quality of solutions." />
      <meta name="robots" content="index, follow" />
      <link rel="icon" href={logo} />

      {/* Open Graph meta tags */}
      <meta property="og:title" content="Bruno Zamani - Full Stack Developer Portfolio" />
      <meta property="og:description" content="Explore the portfolio of Bruno Zamani, a Full Stack Developer with expertise in frontend, backend, cyber security and cloud technologies." />
      <meta property="og:image" content={logo} />
      <meta property="og:url" content="https://www.brunokiptoo.com/" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Bruno Zamani" />

      {/* Additional meta tags for your portfolio */}
      {/* ... (Add any other relevant meta tags for your portfolio) */}

      <meta name="keywords" content="Full Stack Developer, Frontend, Backend, Cloud Technologies, Portfolio" />
      <meta name="author" content="Bruno Zamani" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      {/* Twitter Card meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Bruno Zamani - Full Stack Developer Portfolio" />
      <meta name="twitter:description" content="Explore the portfolio of Bruno Zamani, a Full Stack Developer with expertise in frontend, backend, and cloud technologies." />
      <meta name="twitter:image" content={logo} />
      <meta name="twitter:site" content="@zamani_bruno" />

      {/* Instagram meta tags */}
      <meta property="og:instagram:card" content="summary_large_image" />
      <meta property="og:instagram:title" content="Bruno Zamani - Full Stack Developer Portfolio" />
      <meta property="og:instagram:description" content="Explore the portfolio of Bruno Zamani, a Full Stack Developer with expertise in frontend, backend, and cloud technologies." />
      <meta property="og:instagram:image" content={logo} />

      {/* LinkedIn meta tags */}
      <meta property="og:linkedin:title" content="Bruno Kiptoo - Full Stack Developer Portfolio" />
      <meta property="og:linkedin:description" content="Explore the portfolio of Bruno Zamani, a Full Stack Developer with expertise in frontend, backend, and cloud technologies." />
      <meta property="og:linkedin:image" content={logo} />
      <meta property="og:linkedin:url" content="https://www.linkedin.com/in/brunokiptoo/" />

      {/* TikTok meta tags */}
      <meta property="og:tiktok:title" content="Bruno Zamani - Full Stack Developer Portfolio" />
      <meta property="og:tiktok:description" content="Explore the portfolio of Bruno Zamani, a Full Stack Developer with expertise in frontend, backend, and cloud technologies." />
      <meta property="og:tiktok:image" content={logo} />
      <meta property="og:tiktok:url" content="https://www.tiktok.com/@your-tiktok-handle/" />

      {/* Facebook meta tags */}
      <meta property="og:facebook:title" content="Bruno Zamani - Full Stack Developer Portfolio" />
      <meta property="og:facebook:description" content="Explore the portfolio of Bruno Zamani, a Full Stack Developer with expertise in frontend, backend, and cloud technologies." />
      <meta property="og:facebook:image" content={logo} />
      <meta property="og:facebook:url" content="https://www.facebook.com/bruno.kiptoo/" />

      {/* Your analytics or tracking scripts */}
      {/* ... (Add any analytics or tracking scripts you may have) */}
    </Helmet>
  );
};

export default Seo;
